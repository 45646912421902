<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <article>
      <asom-card>
        <form class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
          <asom-form-field
            :label="'Location Name'"
            :state="inputStates('formData.locationName')"
            error="Location Name is required"
            required
          >
            <asom-input-text
              v-model="formData.locationName"
              :state="inputStates('formData.locationName')"
            />
          </asom-form-field>
          <asom-form-field
            label="Remarks"
            :state="inputStates('formData.remarks')"
            error="Remarks is required"
            required
          >
            <asom-input-textarea
              type="text"
              v-model="formData.remarks"
              :state="inputStates('formData.remarks')"
              :maxlength="1000"
            />
          </asom-form-field>
          <div class="col-span-2 flex justify-end space-x-4 pt-8">
            <asom-button
              text="Back"
              variant="secondary"
              @click="$router.go(-1)"
            />
            <asom-button
              text="Create"
              @click="createItemClicked"
              :loading="isSubmitting"
            />
          </div>
        </form>
      </asom-card>
    </article>
  </div>
</template>

<script>
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { createInventoryLocation } from "../../../../services/inventory.service";

export default {
  setup: () => ({ v$: useVuelidate() }),
  mixins: [inputStates],
  data() {
    return {
      error: null,
      isSubmitting: null,
      formData: {
        locationName: null,
        remarks: null,
      },
    };
  },
  validations() {
    return {
      formData: {
        locationName: { required },
        remarks: { required },
      },
    };
  },
  methods: {
    createItemClicked() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.onSubmit();
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      this.isSubmitting = true;
      const result = await createInventoryLocation({
        locationName: this.formData.locationName,
        remarks: this.formData.remarks,
      });
      if (result.success) {
        this.isSubmitting = false;
        this.$router.go(-1);
      } else {
        this.isSubmitting = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
  },
};
</script>
